import React from 'react';
import _ from 'lodash';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import { Chart } from '../../helpers';
import { PROJECT_STAGES } from '../utils';
import { OrderTypesColor } from '../utils/chart_utils';

const OrderTypes = [
  'SALES ORDER',
  'ESTIMATE',
  'PRESENTATION',
  'OPPORTUNITY',
];
const isPreSales = (order_type) => ['ESTIMATE', 'PRESENTATION', 'OPPORTUNITY'].includes(order_type);

const OpenOrdersCharts = React.memo((props) => {
  const {
    data,
    statuses,
    order_type = 'PRE-SALES',
    onClickColumn,
  } = props;

  const openOrdersChartData = React.useMemo(() => {
    const statusByTypesGroup = _.groupBy(_.values(statuses || {}), 'parent_type');
    const statusByTypes = OrderTypes
      .filter(t => order_type === 'PRE-SALES' ? isPreSales(t) : t === order_type)
      .map(k => ({
        [k]: _.orderBy(statusByTypesGroup[k], [v => PROJECT_STAGES[v.parent_type].order, 'flow_order'], ['desc', 'desc'])
              .filter(v => {
                return !['Closed', 'Converted', 'Invoiced', 'Exported'].includes(v.status_name);
              })
              .map(v => ({ order: PROJECT_STAGES[v.parent_type].order * v.flow_order, name: v.status_name }))
      }))
      .reduce((acc, v) => ({ ...acc, ...v, }), {}) || {};

    const result = {};
    OrderTypes.forEach(v => {
      const title = PROJECT_STAGES[v].title;

      const orderType = v.toLowerCase().split(' ').join('_');
      const status_names = _.get(statusByTypes, [v], []);
      if (!_.get(result, [v], false)) {
        result[v] = { name: title, value: 0, };
      }

      if (!_.get(result, [v, 'subData'], false)) {
        result[v]['subData'] = [];
      }

      for (const status of status_names) {
        const st = status.name.toLowerCase().split(' ').join('_');
        const ot = orderType.toLowerCase().split(' ').join('_');
        const key = `${ot}_num_${st}`;

        const value = parseInt(_.get(data, [key], '0') || 0);
        result[v]['value'] = value + parseInt(result[v]['value'] || 0);
        result[v]['subData'].push({
          name: isPreSales(v) ? `${title} ${status.name}` : status.name,
          value: value,
          order_type: v,
          title: title,
          status_name: status.name,
          pieColor: _.get(OrderTypesColor, [v, status.name], OrderTypesColor[v].main),
          columnColor: _.get(OrderTypesColor, [v, status.name], OrderTypesColor[v].main),
        });
      }
    });

    return Object.values(result).reduce((acc, v) => {
      return [
        ...acc,
        ..._.get(v, ['subData'], []),
      ];
    }, []);
  }, [data, statuses, order_type]);

  return (
    <Chart
      type="bar"
      width="100%"
      data={openOrdersChartData}
      updateChart={(chart, _data) => {
        chart.data = _data;
      }}
      onChartReady={openOrdersColumnChart({
        value: "value",
        category: "name",
        onClickColumn,
      })}
      height="350px"
    />
  );
});

export function openOrdersColumnChart({ onClickColumn, value = "value", category = "category", }) {
  /**
   * @param {am4charts.XYChart} chart
   * @param {any} data
   */
  return function (chart, data) {
    /** @type {am4charts.CategoryAxis}  */
    const categoryAxis = chart.yAxes.push(new am4charts.CategoryAxis());
    categoryAxis.dataFields.category = category;
    categoryAxis.renderer.grid.template.location = 0;
    categoryAxis.renderer.labels.template.wrap = true;
    categoryAxis.renderer.grid.template.disabled = true;
    categoryAxis.renderer.line.strokeOpacity = 0;
    categoryAxis.tooltip.disabled = true;

    categoryAxis.renderer.labels.template.events.on("hit", function (ev) {
      onClickColumn && onClickColumn(ev.target.dataItem.dataContext);
    }, this);

    const valueAxis = chart.xAxes.push(new am4charts.ValueAxis());
    valueAxis.min = 0;
    valueAxis.tooltip.disabled = true;
    valueAxis.renderer.minGridDistance = 100;
    valueAxis.renderer.grid.template.disabled = true;
    valueAxis.renderer.labels.template.disabled = true;
    valueAxis.renderer.line.strokeOpacity = 0;

    /** @type {am4charts.ColumnSeries}  */
    const columnSeries = chart.series.push(new am4charts.ColumnSeries());
    columnSeries.dataFields.valueX = value;
    columnSeries.dataFields.categoryY = category;
    columnSeries.columns.template.strokeWidth = 0;
    columnSeries.tooltip.disabled = false;
    columnSeries.tooltipText = "{categoryY}: {valueX}";
    columnSeries.columns.template.propertyFields.fill = "columnColor";

    chart.maskBullets = false;
    chart.paddingRight = 30;
    chart.cursor = new am4charts.XYCursor();
    chart.cursor.lineY.disabled = true;
    chart.cursor.lineX.disabled = true;
    chart.cursor.tooltipText = "{categoryY}: {valueX}";

    columnSeries.columns.template.events.on("hit", function (ev) {
      onClickColumn && onClickColumn(ev.target.dataItem.dataContext);
    }, this);

    columnSeries.tooltip.cursorOverStyle = am4core.MouseCursorStyle.pointer;
    columnSeries.tooltip.keepTargetHover = true;
    columnSeries.tooltip.events.on("hit", function (ev) {
      onClickColumn && onClickColumn(ev.target.dataItem.dataContext);
    }, this);

  };
}

export default OpenOrdersCharts;
